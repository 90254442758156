<template>
  <v-app>
    <div class="asin-interlocked">
      <h2>Amazon出品情報入力</h2>
      <v-form ref="form" id="inspire">
        <v-flex>
          <input type="radio" id="normal" v-model="inventoryType" value="1"/>
          <label for="normal">通常在庫</label>
          <br/>
          <input type="radio" id="bad" v-model="inventoryType" value="2"/>
          <label for="bad">項目選択肢別在庫（バリエーションあり）</label>
          <v-layout row>
            <v-col cols="6" sm="3">
              <v-text-field
                  v-model="priceRatio"
                  label="利益幅 入力例:1.5"
                  type="number"
                  step="0.1"
                  :rules="[rules.required, rules.priceRate]"
                  required
              ></v-text-field>
            </v-col>
          </v-layout>
          <div v-if="is_multiple">
            <v-layout row>
              <v-col cols="6" sm="3">
                <v-text-field
                    v-model="horizontalName"
                    label="横軸項目名 入力例:カラー"
                    maxlength="200"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                    v-model="verticalName"
                    label="縦軸項目名 入力例:サイズ"
                    maxlength="200"
                ></v-text-field>
              </v-col>
            </v-layout>
            <v-btn @click="addInput()">追加</v-btn>
            <v-layout row v-for="input in multipleInputList" :key="input.id">
              <v-col cols="6" sm="2">
                <v-text-field
                    v-model="input.asinCode"
                    label="ASINコード"
                    maxlength="10"
                    :rules="[rules.required, rules.asinCode]"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                    v-model="input.optionNameHorizontal"
                    label="横軸名 入力例:赤"
                    maxlength="200"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" v-if="use_vertical">
                <v-text-field
                    v-model="input.optionNameVertical"
                    label="縦軸名 入力例:S"
                    maxlength="200"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-col>
              <v-btn fab dark small color="red" @click="removeInput(input.id)"
              >削除
              </v-btn
              >
            </v-layout>
          </div>
          <div v-else>
            <v-layout>
              <v-col cols="6" sm="2">
                <v-text-field
                    v-model="singleAsinCode"
                    :rules="[rules.required, rules.asinCode]"
                    label="ASINコード"
                    maxlength="10"
                    required
                ></v-text-field>
              </v-col>
            </v-layout>
          </div>
          <v-btn @click="itemRegist()">出品</v-btn>
        </v-flex>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-form>
    </div>
    <snackbar>
    </snackbar>
  </v-app>
</template>

<script>
import axios from "axios";
import Snackbar from "@/components/snackbar";
import {mapActions} from "vuex";

export default {
  name: "Asin-interlocked",
  components: {Snackbar},
  computed: {
    is_multiple() {
      return this.inventoryType == "2";
    },
    use_vertical() {
      return this.is_multiple && this.verticalName != "";
    },
  },
  data() {
    return {
      inventoryType: "1",
      multipleInputList: [
        {
          id: 0,
          asinCode: "",
          optionNameHorizontal: "",
          optionNameVertical: "",
        },
      ],
      singleAsinCode: "",
      priceRatio: 1.5,
      overlay: false,
      horizontalName: "",
      verticalName: "",
      rules: {
        required: (value) => !!value || "必須項目です",
        priceRate: (value) => {
          return 1.0 < value || "利益幅は1.1以上の数値で入力してください";
        },
        asinCode: (value) => {
          return 10 == value.length || "ASINコードは10桁で入力してください";
        },
      },
    };
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    addInput() {
      let multipleInputList = this.multipleInputList;
      multipleInputList.push({
        id: multipleInputList.length,
        text: "",
      });
    },
    removeInput(id) {
      let multipleInputList = this.multipleInputList;
      multipleInputList = multipleInputList.filter((input) => {
        return input.id !== id;
      });
      let newmultipleInputList = [];
      for (let i = 0; i < multipleInputList.length; i++) {
        newmultipleInputList.push({
          id: i,
          text: multipleInputList[i].text,
        });
      }
      this.multipleInputList = newmultipleInputList;
    },
    async itemRegist() {
      const result = this.$refs.form.validate();
      if (result == false) return;

      this.overlay = true;
      const childItemsParam = this.createChildItems(this.multipleInputList);
      const multipleParams = {
        inventoryType: this.inventoryType,
        horizontalName: this.horizontalName,
        verticalName: this.verticalName,
        priceRatio: this.priceRatio,
        childItems: childItemsParam,
      };
      const singleParam = {
        inventoryType: this.inventoryType,
        priceRatio: this.priceRatio,
        childItems: [
          {
            asinCode: this.singleAsinCode,
          },
        ],
      };

      const requestParams =
          this.inventoryType == "2" ? multipleParams : singleParam;

      try {
        await axios
            .post("/items", requestParams, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then(() => {
              this.overlay = false;
              this.openSnackbar("商品登録が完了しました。");
              location.reload();
            });
      } catch (e) {
        this.overlay = false;
        if (e.response.data.detail.msg != undefined) {
          this.openSnackbar(e.response.data.detail.msg);
          return;
        }
        if (e.response.status == 403) {
          this.openSnackbar("認証エラーが発生しました。ログインしなおしてください。");
          // 認証失敗時に再ログインさせる
          localStorage.removeItem("token");
          location.reload();
          return;
        }
        this.openSnackbar(e);
      }
    },

    createChildItems(itemList) {
      const childItemList = [];
      itemList.forEach((item) => {
        const childItem = {
          asinCode: item.asinCode,
          optionNameHorizontal: item.optionNameHorizontal,
          optionNameVertical: item.optionNameVertical,
        };
        childItemList.push(childItem);
      });
      return childItemList;
    },
  },
};
</script>
